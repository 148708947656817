<template>
  <main class="my-orders-tab">
    <LcExpandable
      v-model="isActiveExpanded"
      content-class="pt-md"
      :disabled="!showActiveList || isLoading"
    >
      <template #header>{{ $t(`${activePath}.title`) }}</template>

      <div v-if="!isLoading && totalActive === 0 && isDataLoaded" class="pb-xs">
        <slot name="no-results" />
      </div>

      <LcRemoteList
        v-else-if="showActiveList"
        :button-text="$t(`${activePath}.button.loadmore`)"
        :count="countActive"
        hide-separator
        :loading="isLoading || loadingActive"
        :total="totalActive"
        @fetch-more="fetchMoreActive"
      >
        <slot
          v-if="isDataLoaded && resultActive"
          name="active"
          :result="resultActive"
        />
      </LcRemoteList>
    </LcExpandable>

    <LcSeparator
      v-if="showPastList || countActive < totalActive || !isActiveExpanded"
      class="my-md"
    />

    <LcExpandable
      v-if="showPastList"
      v-model="isPastExpanded"
      content-class="pt-md"
      :disabled="isLoading"
    >
      <template #header>{{ $t(`${pastPath}.title`) }}</template>

      <LcRemoteList
        :button-text="$t(`${pastPath}.button.loadmore`)"
        :count="countPast"
        hide-separator
        :loading="isLoading || loadingPast"
        :total="totalPast"
        @fetch-more="fetchMorePast"
      >
        <slot
          v-if="isDataLoaded && resultPast"
          name="past"
          :result="resultPast"
        />
      </LcRemoteList>
    </LcExpandable>

    <LcSeparator
      v-if="isScrollUpButtonVisible || countPast < totalPast || !isPastExpanded"
      class="my-md"
    />

    <div v-if="isScrollUpButtonVisible" class="flex w-full justify-center">
      <LcButton
        icon="arrow-up"
        size="medium"
        variant="secondary"
        @click="scrollTop(0)"
      />
    </div>
  </main>
</template>

<script lang="ts" setup>
import {
  LcButton,
  LcExpandable,
  LcRemoteList,
  LcSeparator,
} from '@lottocom/frontend-components'
import { invoke, until } from '@vueuse/core'

import type { MyOrdersTabImplementationProps } from '~/features/MyOrders/types'

type FetchFn = () => ReturnType<typeof useFetchMoreWrapper<any>>

type MyOrdersTabProps = MyOrdersTabImplementationProps & {
  fetchActive: FetchFn
  fetchPast: FetchFn
  type: string
}

const props = defineProps<MyOrdersTabProps>()

const { addToastError } = useToaster()
const { scrollTop } = useScrollUtils()

const isActiveExpanded = ref(true)
const isRefetchLoading = ref(false)
const isDataLoadedInitially = ref(false)
const isPastExpanded = ref(true)

const dataWasLoadedInitially = useDebounceFn(
  () => (isDataLoadedInitially.value = true),
  500,
)

const disableRefetchLoadingDebounced = useDebounceFn(
  () => (isRefetchLoading.value = false),
  250,
)

const {
  count: countActive,
  fetchMore: fetchMoreActive,
  load: loadActive,
  loading: loadingActive,
  refetch: refetchActive,
  result: resultActive,
  total: totalActive,
} = props.fetchActive()

const {
  count: countPast,
  fetchMore: fetchMorePast,
  load: loadPast,
  loading: loadingPast,
  refetch: refetchPast,
  result: resultPast,
  total: totalPast,
} = props.fetchPast()

const activePath = computed(() => `${basePath.value}.active`)

const basePath = computed(() => `myorders.tabs.${props.type}.section`)

const isDataLoaded = computed(
  () => !isRefetchLoading.value && isDataLoadedInitially.value,
)

const isLoading = computed(
  () => !isDataLoadedInitially.value || isRefetchLoading.value,
)

const pastPath = computed(() => `${basePath.value}.past`)

const showActiveList = computed(
  () => isLoading.value || loadingActive.value || countActive.value > 0,
)

const showPastList = computed(
  () => isLoading.value || loadingPast.value || countPast.value > 0,
)

const isScrollUpButtonVisible = computed(() => {
  const visibleActiveOrders = isActiveExpanded.value ? totalActive.value : 0
  const visiblePastOrders = isPastExpanded.value ? totalPast.value : 0

  return visibleActiveOrders + visiblePastOrders >= 6
})

const shouldFetchInitially = computed(
  () => !isDataLoadedInitially.value && props.visible,
)

invoke(async () => {
  await until(shouldFetchInitially).toBeTruthy()

  try {
    loadActive()
    loadPast()
  } catch (error) {
    addToastError({ error, prefix: 'myorders.error' })
  } finally {
    await dataWasLoadedInitially()
  }
})

watch(
  () => props.visible,
  async (visible) => {
    if (!visible && isDataLoadedInitially.value) isRefetchLoading.value = true
    if (!visible || !isDataLoadedInitially.value) return

    try {
      await refetchActive()
      await refetchPast()
    } finally {
      await disableRefetchLoadingDebounced()
    }
  },
)
</script>

<style lang="scss" scoped>
.my-orders-tab {
  min-height: 70vh;
}
</style>
