<template>
  <LcNoResults
    v-bind="{ src }"
    :alt="$t(`${translationBasePath}.alt`)"
    variant="outlined"
    @cta-click="navigateToPlayNow"
  >
    <template v-if="!isSelfExcluded" #button-text>
      {{ $t(`${translationBasePath}.button.text`) }}
    </template>

    <template #headline>
      {{ $t(`${translationBasePath}.headline`) }}
    </template>

    <template #subline>
      {{ $t(`${translationBasePath}.subline`) }}
    </template>
  </LcNoResults>
</template>

<script lang="ts" setup>
import { LcNoResults } from '@lottocom/frontend-components'

const PLAY_NOW = '/play-now'

type MyOrdersNoResultsProps = {
  variant: 'scratchcard' | 'single-order' | 'subscription'
}

const props = defineProps<MyOrdersNoResultsProps>()

const { result } = useUserStatusQuery({ fetchPolicy: 'cache-first' })

const src = computed(() => {
  if (props.variant === 'single-order')
    return '/assets/img/illustration/no-single-orders.svg'
  if (props.variant === 'subscription')
    return '/assets/img/illustration/no-subscriptions.svg'
  return '/assets/img/illustration/no-scratchcards.svg'
})

const isSelfExcluded = computed(
  () => !!result.value?.player?.selfExclusion?.type,
)

const translationBasePath = computed(
  () => `myorders.tabs.${props.variant}.noresults`,
)

const navigateToPlayNow = () => {
  navigateTo(
    props.variant === 'scratchcard' ? `${PLAY_NOW}/scratchcards` : PLAY_NOW,
  )
}
</script>
